@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https:////db.onlinewebfonts.com/c/800e985f75a5a56c2f7b4be502d286de?family=Burbank+Big+Condensed);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Merriweather Sans', sans-serif;
}

@font-face {
  font-family: "Burbank Big Condensed";
  src: url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot");
  src: url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff") format("woff"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.svg#Burbank Big Condensed") format("svg");
}

.button-62:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.rewardsSection{
  margin-top: 5%;
  background-color: #05132096;
  padding-top: 30px;
  padding-bottom: 30px;
}

.rewards{
  color: white;
  font-size: 25px;
  text-align: center;
  margin-bottom: 10px;
}

.rewardsBtn {
  background: rgb(255, 124, 124);
  background: linear-gradient(180deg, rgba(255, 124, 124, 1) 0%, rgba(245, 24, 24, 1) 35%);
  box-shadow: 0px 5px rgb(175, 11, 11);
  border: none;
  border-radius: 50px;
  width: 200px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  padding: 15px;
  font-size: 20px;
}

.rewardsBtn:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cont {
  background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url(../../static/media/bg.d51f7ede.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
}


.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: linear-gradient(54deg, rgba(0, 0, 0, 0) 0%, rgba(19, 19, 19, 0.2909335609243697) 41%, rgba(19, 19, 19, 0.5710456057422969) 100%), url(../../static/media/cave.20655c3f.jpg) no-repeat center center;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}

.rmbg {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: rgb(32, 12, 61);
  background: linear-gradient(180deg, rgba(32, 12, 61, 1) 0%, rgba(116, 40, 62, 1) 49%, rgba(14, 16, 62, 1) 100%);
  padding-top: 50px;
}

.faqbg {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: rgb(15, 16, 62);
  background: linear-gradient(180deg, rgba(15, 16, 62, 1) 0%, rgba(52, 12, 61, 1) 49%, rgba(3, 0, 4, 1) 100%);
  padding-bottom: 50px;
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}


@media screen and (max-width: 499px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 25px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .nft {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .in2 {
    font-size: 100px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    margin-bottom: -35px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #fee100 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-min-content;
    height: min-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: "Burbank Big Condensed";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
  }

  .introduction {
    padding-top: 40px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 90px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }


  .storyHK {
    font-size: 57px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
  }

  .logos_mobile {
    width: 70%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 85%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    color: white;
    font-size: 40px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: #ff2438;
  }



  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 5px rgb(0, 45, 71);
    font-size: 16px;
    padding: 12px;
    width: 180px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 20px;
  }



  .logo2 img {
    width: 55px;
    height: 55px;
  }

  .downImg {
    width: 30px;
    height: 30px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: -50px;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-left: -25px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";
    margin-left: -65px;
    width: 195px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 19px;
    margin-left: -65px;
    width: 220px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 6px;
    background-color: #fee100;
    left: 38px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 6px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 38px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }


  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 18px;
    margin-left: -65px;
    width: 200px;
  }

  .wand {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .fireGlow {
    -webkit-animation: glow 2s ease-in-out infinite alternate;
            animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }



  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }



  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 80%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 1;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 30px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .nft {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .in2 {
    font-size: 100px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    margin-bottom: -35px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #fee100 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: "Burbank Big Condensed";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 75%;
  }

  .introduction {
    padding-top: 30px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 100px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }

  .storyHK {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 75%;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
  }

  .logos_mobile {
    width: 65%;
    height: 65%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 85%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }


  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 95%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    color: white;
    font-size: 45px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 5px rgb(0, 45, 71);
    font-size: 18px;
    padding: 12px;
    width: 200px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 20px;
  }



  .logo2 img {
    width: 78px;
    height: 78px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 40px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";
    margin-left: -40px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 19px;
    margin-left: -40px;
    width: 300px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 41px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 41px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 20px;
    margin-left: -40px;
    width: 330px;
  }

  .wand {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 55px;
    height: 55px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 85%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 30px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .nft {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .in2 {
    font-size: 130px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    margin-bottom: -35px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #fee100 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Burbank Big Condensed";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
  }

  .introduction {
    padding-top: 30px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 100px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }

  .storyHK {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 60%;
    height: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
  }

  .logos_mobile {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 5px rgb(0, 45, 71);
    font-size: 18px;
    padding: 12px;
    width: 200px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 20px;
  }



  .logo2 img {
    width: 78px;
    height: 78px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .wand {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .fireGlow {
    -webkit-animation: glow 2s ease-in-out infinite alternate;
            animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 89%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 1;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 30px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .nft {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .in2 {
    font-size: 130px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    margin-bottom: -35px;
  }

  .miniBarMain {
    display: none;
  }

  .littleNav {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Burbank Big Condensed";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 100px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }

  .storyHK {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1f1f1f00;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    box-shadow: 0px 5px #ed162b;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 5px rgb(0, 45, 71);
    font-size: 18px;
    padding: 12px;
    width: 200px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 20px;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .wand {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 30px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .in2 {
    font-size: 130px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .nft {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -50px;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .introduction {
    padding-top: 15px;
    font-family: "Burbank Big Condensed";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 120px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1f1f1f00;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    box-shadow: 0px 5px #ed162b;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 5px rgb(0, 45, 71);
    font-size: 18px;
    padding: 12px;
    width: 200px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 20px;
  }



  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: white;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .wand {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .fireGlow {
    -webkit-animation: glow 2s ease-in-out infinite alternate;
            animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1920px) {
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 42px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 110px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 50px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 33px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 36px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 26px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .in2 {
    font-size: 200px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .nft {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -80px;
  }

  .miniBarMain {
    display: none;
  }

  .littleNav {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 30px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Burbank Big Condensed";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 160px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }


  .storyHK {
    font-size: 110px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 70px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 70px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 27px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 28px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 27px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 80px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
    font-size: 28px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
    font-size: 28px;
  }

  .devs img {
    width: 45px;
    height: 45px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1f1f1f00;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-top: 10px;
    box-shadow: 0px 7px #ed162b;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 30px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 7px rgb(0, 45, 71);
    font-size: 30px;
    padding: 15px;
    width: 300px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 50px;
  }




  .logo2 img {
    width: 110px;
    height: 110px;
  }

  .downImg {
    width: 50px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 70px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    width: 600px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 64.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 64.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 30px;
    width: 600px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .wand {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 90px;
    height: 90px;
    opacity: 0.5;
    transition: 0.5s ease;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;

    transition: -webkit-transform .4s;

    transition: transform .4s;

    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 18px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 48px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 120px;
    height: 120px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 55px;
    border-radius: 60px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 150px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 60px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 40px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 30px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .in2 {
    font-size: 250px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .nft {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -80px;
  }

  .miniBarMain {
    display: none;
  }

  .littleNav {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }


  .in2 p {
    font-size: 50px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Burbank Big Condensed";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 190px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }


  .storyHK {
    font-size: 160px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 95px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 95px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 32px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 37px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 33px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }



  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 110px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
    font-size: 37px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    margin-right: 35px;
    text-align: center;
    margin-top: 80px;
    font-size: 35px;
  }

  .devs img {
    width: 55px;
    height: 55px;
    opacity: 0.9;
    transition: 0.3s ease;
    margin-left: 15px;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1f1f1f00;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 45px;
    padding-bottom: 45px;
    margin-top: 10px;
    box-shadow: 0px 10px #ed162b;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 10px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 75px;
    height: 75px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 40px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 10px rgb(0, 45, 71);
    font-size: 40px;
    padding: 20px;
    width: 420px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 50px;
  }




  .logo2 img {
    width: 140px;
    height: 140px;
  }

  .downImg {
    width: 80px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: 70px;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 90px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 38px;
    width: 800px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 63.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 63.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 45px;
    width: 800px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .wand {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  @-webkit-keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 140px;
    height: 140px;
    opacity: 0.5;
    transition: 0.5s ease;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;

    transition: -webkit-transform .4s;

    transition: transform .4s;

    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 3840px) {

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 18px #7f8082;
    border-style: double;
    z-index: 10000000;

  }

  .mintImg {
    width: 70%;
  }

  .mintImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 180px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 60px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 160px;
    height: 160px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    border-radius: 90px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: gold;
    font-size: 190px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: gold;
    font-size: 60px;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 40px;
    color: white;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 30px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  .in2 {
    font-size: 400px;
    text-align: center;
    font-family: "Burbank Big Condensed";
    color: white;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .nft {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -80px;
  }

  .miniBarMain {
    display: none;
  }

  .littleNav {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .in2 p {
    font-size: 70px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Burbank Big Condensed";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }


  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(17, 56, 65);
    background: linear-gradient(180deg, rgba(17, 56, 65, 1) 0%, rgba(7, 38, 45, 1) 38%, rgba(22, 11, 24, 1) 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 280px;
    color: #fee100;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #d69f01, 0 2px 0 #d69f01, 0 3px 0 #d69f01, 0 4px 0 #d69f01, 0 5px 0 #d69f01, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
  }


  .storyHK {
    font-size: 200px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 50px;
    text-shadow: 0 -1px 8px #FFF, 0 -2px 20px rgba(255, 255, 0, 0.616), 0 -10px 35px #ff80008c, 0 -18px 55px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 150px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 150px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 52px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutP p {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .nftIcon {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 50px;
    background: rgba(19, 19, 19, 0.767);

    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    border-radius: 100px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 45px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }



  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .logoF {
    text-align: center;
    font-family: "Burbank Big Condensed";
    margin-bottom: 10px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    letter-spacing: 3px;
    color: white;
    font-size: 150px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: white;
    text-align: center;
    font-size: 45px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    margin-right: 35px;
    text-align: center;
    margin-top: 80px;
    font-size: 45px;
    margin-bottom: 40px;
  }

  .devs img {
    width: 65px;
    height: 65px;
    opacity: 0.9;
    transition: 0.3s ease;
    margin-left: 15px;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1f1f1f00;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 55px;
    padding-bottom: 55px;
    margin-top: 10px;
    box-shadow: 0px 15px #ed162b;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 10px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
  }

  .connect div:hover {
    color: #ff2438;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 95px;
    height: 95px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 55px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: rgb(0, 63, 100);
    box-shadow: 0px 10px rgb(0, 45, 71);
    font-size: 55px;
    padding: 20px;
    width: 580px;
    border: none;
    color: rgb(255, 255, 255);
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 50px;
  }




  .logo2 img {
    width: 220px;
    height: 220px;
  }

  .downImg {
    width: 110px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: 160px;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 90px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    color: white;
    font-family: "Burbank Big Condensed";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 38px;
    width: 800px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fee100;
    left: 63.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 63.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fee100;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 45px;
    width: 800px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .wand {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .stickyBtn2 {
    width: 170px;
    height: 170px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;
  }
}
